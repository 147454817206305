<template>
    <v-container fill-height>
          <v-card outlined max-width="500"  class="ma-auto justify-center" >
            <v-card-text>
              <v-row class="pa-1 justify-center">                                
                  <v-avatar size="120">
                        <img
                          src="@/assets/logo_redondo.png"
                          alt="Rincon Club"
                        >
                </v-avatar>
              </v-row>            
              <v-form ref="form" v-on:submit.prevent="login" v-model="validado">
                <v-text-field 
                              required
                              :rules="loginUserRules"
                              v-model="loginuser"
                              hide-details="" 
                              autofocus
                              label="Usuario" 
                              prepend-icon="mdi-account-circle"
                              placeholder=" " />
                <v-text-field            
                            required
                            @keyup.13="login()"
                            :rules="loginPasswordRules"
                            :type="'password'"
                            v-model="loginpassword"
                            label="Password"
                            prepend-icon="mdi-lock"
                            placeholder=" "
                            hide-details=""
                            autocomplete="new-password"
                             />
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-container>
                <v-row>
                  <v-col>
                    <v-btn :disabled="!validado"  block  outlined :color=colorMenu @click=login()>Login</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn disabled block color="success">Registrar</v-btn>
                    </v-col>
                </v-row>   
                <v-row>
                  <v-col >
                        <v-btn disabled> 
                        <v-icon left>mdi-google</v-icon>
                        Login Google
                      </v-btn> 
                      </v-col>
                      <v-col >
                        <v-btn disabled > 
                        <v-icon left>mdi-facebook</v-icon>
                        Login Facebook
                      </v-btn>
                      </v-col>
                  </v-row>
            </v-container>
            </v-card-actions>
          </v-card>



      

          <v-snackbar top  v-model="snackbar" :timeout="timeOutSnack" :color="colorSnack">
                  {{ textoError}}
                  <template v-slot:action="{ attrs }">
                            <v-btn :color=colorMenu text v-bind="attrs" @click="snackbar = false">
                                    Cerrar
                            </v-btn>
                  </template>
       </v-snackbar> 
    </v-container>  
</template>
<script>
import axios from 'axios'
import encriptacion from '@/services/encriptacion.js'
import { mapState } from 'vuex'
export default {
             // layout:'pruebas',
              data(){
                    return{
                            colorMenu:'#2E7D32',
                            textoError:'',
                            colorSnack:'success',
                            timeOutSnack:6000,
                            snackbar:false,
                            loginUserRules:[
                                        v => !!v || 'El usuario es requerido',                                         
                                        ],
                            loginPasswordRules:[v=>!!v || 'El password es requerido'],
                            validado:true,
                            showPassword: false,
                            loginuser:'',
                            loginpassword:''
                          }
                    },
              created(){
                   //    this.$store.commit('grabarLayout', 'login-layout')
              },              
              computed:{
                        ...mapState(['autenticado','headers']) 
              },
              methods: {
                        mensaje(texto,tipo){
                                          //this.timeOutSnack=6000
                                          if (tipo=="error"){
                                              this.colorSnack='error'
                                          }
                                          if (tipo=="info"){
                                             this.colorSnack='success'
                                             this.timeOutSnack=6000
                                          }                                             
                                          this.textoError= texto
                                          this.snackbar=true 
                        },
                       
                        
                        async login(){
                                      let password_enc= await encriptacion.encriptarMD5(this.loginpassword)
                                      //alert(password_enc.toString())
                                      await axios.post(process.env.VUE_APP_API + '/user/login/', {
                                                     user: this.loginuser,
                                                      clave: password_enc.toString()                                                    
                                          })
                                          .then(res =>{
                                                      
                                                      if (res.status==200){
                                                        //   alert(JSON.stringify(res.data))
                                                         //  alert(res.data.idcomplejo)

                                                            this.$store.dispatch('login',{userName: res.data.usuario,userId: res.data.id, token: res.data.token})
                                                            axios.defaults.headers.common['authorization'] = this.headers 
                                                            if (res.data.idcomplejo=='1'){
                                                              this.$router.replace({ path:"/dashboard/rdaadmin/"})
                                                            }
                                                            if (res.data.idcomplejo=='2'){
                                                                  this.$router.replace({ path:"/dashboard/rdeadmin/"})    
                                                            }
                                                            
                                                      }
                                                      else{
                                                           this.mensaje("Error de Login","error")
                                                           //alert ('Error de login')
                                                      }                                                                                             
                                          })
                                              .catch(
                                                this.mensaje("Verifique su usuario y contraseña","error")
                                             )
                      }
              }            
            }
</script>